<template>
  <div class="profile-root">
    <div class="profile-bg-container">
      <v-avatar size="120">
        <img
          :src="avatar"
          :alt="user.name"
        >
      </v-avatar>
    </div>
    <v-list>
      <v-list-item-group>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="user.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'退出'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Profile',
  data: () => ({
    avatar: 'https://picsum.photos/seed/id/300/300'
  }),
  computed: {
    ...mapState([
      'user'
    ])
  },
  mounted () {
    this.avatar = this.avatar.replace('id', this.user.id)
    this.$store.commit('updateRoute', this.$route.name)
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.replace('/login')
    }
  }
}
</script>

<style scoped>
  .profile-root {
    width: 100%;
    height: 100%;
  }
  .profile-bg-container {
    height: 300px;
    width: 100%;
    background-color: dodgerblue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
